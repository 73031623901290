<template>
  <div class="content-404">
    <div class="content-404-itens container">
      <h2 class="content-404-itens-subtitle">Error 404...</h2>
      <h1 class="content-404-itens-title">Page Not Found</h1>
      <p class="content-404-itens-text">
        We looked everywhere for this page. Are you sure the website URL is
        correct? Go to the
        <router-link class="content-404-itens-link" to="/">
          main page
        </router-link>
        or select suitable category.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style lang="scss">
.content-404 {
  padding: 200px 0px;
	@media (max-width: 576px){
		padding: 100px 0px;
	}
  &-itens {
    &-subtitle {
      color: #5CA3D8;
      font-size: 36px;
      font-family: "Font Black";
      font-weight: 800;
      padding: 0;
      margin: 0;
			@media (max-width: 576px){
				font-size: 30px;
			}
    }
    &-title {
      font-size: 64px;
      font-family: "Font Black";
      color: var(--blue-dark);
      margin-top: 16px;
			@media (max-width: 576px){
				font-size: 40px;
			}
    }
    &-text {
			font-size: 20px;
			font-family: 'Font Book';
			color: #707070;
			margin-bottom: 0;
			max-width: 665px;
			margin: 20px auto 0px auto;
			@media (max-width: 576px){
				font-size: 16px;
			}
    }
    &-link {
			font-size: 20px;
			color: var(--blue-dark) !important;
			font-family: 'Font Black';
			margin-bottom: 0;
			text-decoration: underline;
			text-decoration-color: var(--blue-dark);
			transition: 500ms;
			@media (max-width: 576px){
				font-size: 16px;
			}
			&:hover,&:focus {
				cursor: pointer;
				opacity: 0.6;
			}
    }
  }
}
</style>